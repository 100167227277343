import type { MouseEvent } from "react";
import { Fragment, useState } from "react";

import { StandardTextFieldProps, TextField, TextFieldProps } from "@material-ui/core";
import { SvgIcon } from "@new-black/lyra";
import { cva } from "class-variance-authority";
import classNames from "clsx";
import { omit } from "lodash";

import IconButton from "../icon-button";

export interface IDatePickerField extends StandardTextFieldProps {
  clear?: () => void;
  helpertext?: string;
  handleClick?: (event: MouseEvent<any>) => void;
  small?: boolean;
}

const datePickerFieldClassName = cva(
  [
    "[&_input::-webkit-inner-spin-button]:hidden ",
    "[&_input::-webkit-calendar-picker-indicator]:hidden",
    "[&_input::-webkit-clear-button]:hidden",
    "[&_label]:truncate",
  ],
  {
    variants: {
      small: {
        true: [
          "mt-0",
          "[&_input]:pb-1",
          "[&_.MuiInput-root]:text-legacy-sm",
          "[&_.MuiInputLabel-formControl]:transform-[unset] [&_.MuiInputLabel-formControl]:text-legacy-sm [&_.MuiInput-formControl]:mt-0",
          "[&_.MuiInputLabel-shrink]:translate-x-[2px] [&_.MuiInputLabel-shrink]:translate-y-[-8px] [&_.MuiInputLabel-shrink]:scale-[.8]",
          "[&_.MuiInputLabel-marginDense]:translate-x-0 [&_.MuiInputLabel-marginDense]:translate-y-[4px] [&_.MuiFormControl-marginDense]:my-0 ",
          "[&_.MuiInput-root]:min-h-[32px] [&_.MuiInput-root]:pl-3.5",
        ],
      },
      isShrinked: {
        true: ["[&_label]:w-[calc(133.33%)] "],
      },
    },
  },
);

const DatePickerFieldStyled = ({
  className,
  isShrinked,
  small,
  ...props
}: TextFieldProps & { small?: boolean; isShrinked?: boolean }) => (
  <TextField
    {...props}
    className={classNames(datePickerFieldClassName({ small, isShrinked }), className)}
  />
);

const DatePickerField = (props: IDatePickerField) => {
  const {
    clear,
    disabled,
    handleClick,
    onBlur,
    onFocus,
    onMouseEnter,
    onMouseLeave,
    small,
    value,
  } = props;

  const [isHovering, setIsHovering] = useState<boolean>(false);
  const [isFocus, setIsFocus] = useState<boolean>(false);

  return (
    <DatePickerFieldStyled
      {...omit(props, ["clear", "handleClick", "helpertext"])}
      fullWidth
      isShrinked={!!value || !!isFocus}
      type={isFocus || (value && value !== "") ? "date" : "text"}
      onFocus={(e: any) => {
        setIsFocus(true);
        if (onFocus) {
          onFocus(e);
        }
      }}
      onBlur={(e: any) => {
        setIsFocus(false);
        if (onBlur) {
          onBlur(e);
        }
      }}
      onMouseEnter={(e: any) => {
        setIsHovering(true);
        if (onMouseEnter) {
          onMouseEnter(e);
        }
      }}
      onMouseLeave={(e: any) => {
        setIsHovering(false);
        if (onMouseLeave) {
          onMouseLeave(e);
        }
      }}
      inputProps={{ max: "9999-12-31" }}
      InputProps={{
        endAdornment: (
          <Fragment>
            {isHovering && value && clear && !disabled ? (
              <IconButton onClick={clear} size={small ? "small" : undefined}>
                <SvgIcon name="alert-error" className="h-5 w-5 text-secondary" />
              </IconButton>
            ) : null}
            <IconButton
              onClick={handleClick}
              edge="end"
              size={small ? "small" : undefined}
              disabled={disabled}
            >
              <SvgIcon name="calendar" className="h-5 w-5 text-secondary" />
            </IconButton>
          </Fragment>
        ),
      }}
    />
  );
};

export default DatePickerField;
