import { Fragment, useCallback, useMemo } from "react";

import { v4 as uuid } from "uuid";

import { ISearchListFieldBaseProps } from "./types";

import Chip from "~/components/suite-ui/chip";
import {
  getElementPositionInArray,
  TElementPositionInArray,
} from "~/util/get-element-position-in-array";

type TGetArrayHTMLElementAction = "next" | "previous" | "current" | "first" | "last";

export const useSearchListFieldDisplayValue = <T, IDKey extends keyof T, LabelKey extends keyof T>(
  idKey: IDKey,
  labelKey: LabelKey,
  onDeleteItem: (item: T) => void,
  buttonRef: React.MutableRefObject<HTMLButtonElement | null>,
  multiple?: boolean,
  value?: T,
  values?: T[],
  disabled?: boolean,
  renderValue?: ISearchListFieldBaseProps<T>["renderValue"],
) => {
  const chipsUUIDs = useMemo(() => (values ?? [])?.map(() => uuid()), [values]);

  // Callback that returns next, previous or current HTML element from the array. If the
  // action is not specified, it will try to return the next element; if there is no next
  // element, it will return the previous one
  const getArrayHTMLElement = useCallback(
    (uuid: string, action: TGetArrayHTMLElementAction) => {
      const elementToDelete = chipsUUIDs?.findIndex((item) => item === uuid);

      // If the element is not part of the array or there are no values currently defined, return null
      if (elementToDelete === undefined || !chipsUUIDs?.length) {
        return null;
      }

      switch (action) {
        case "first":
          return document.getElementById(chipsUUIDs[0]);
        case "previous":
          return document.getElementById(chipsUUIDs[elementToDelete - 1]);
        case "current":
          return document.getElementById(chipsUUIDs[elementToDelete]);
        case "next":
          return document.getElementById(chipsUUIDs[elementToDelete + 1]);
        case "last":
          return document.getElementById(chipsUUIDs[chipsUUIDs.length - 1]);
      }
    },
    [chipsUUIDs],
  );

  /**
   * Rover tabIndex for ArrowLeft / ArrowRight key events.
   * @returns the next focusable element
   */
  const roverTabIndex = useCallback(
    (uuid: string, position: TElementPositionInArray, eventCode: "ArrowLeft" | "ArrowRight") => {
      //
      if (position === "lastElement" || position === "none") {
        return;
      }

      const currentFocusedElement = getArrayHTMLElement(uuid, "current");

      let nextFocusableElement = null;

      switch (eventCode) {
        case "ArrowLeft":
          nextFocusableElement = getArrayHTMLElement(
            uuid,
            position === "first" ? "last" : position === "last" ? "previous" : "previous",
          );
          break;
        case "ArrowRight":
          nextFocusableElement = getArrayHTMLElement(
            uuid,
            position === "first" ? "next" : position === "last" ? "first" : "next",
          );
          break;
      }

      if (currentFocusedElement && nextFocusableElement) {
        // Make next element focusable by setting its tab index to 0
        nextFocusableElement.tabIndex = 0;

        // Focus next element
        nextFocusableElement.focus();

        // Make current element unfocusable
        currentFocusedElement.tabIndex = -1;
      }

      return nextFocusableElement;
    },
    [getArrayHTMLElement],
  );

  /**
   * Callback that sets focus on next / previous chips after deleting an item, or restores the focus
   * on the button element if no elements are left after deletion.
   */
  const updateFocusOnDelete = useCallback(
    (uuid: string, position: TElementPositionInArray) => {
      if (position === "lastElement" || position === "none") {
        // If it's the last element in the array, focus the button
        buttonRef.current?.focus();
      } else {
        const nextFocusableElement = roverTabIndex(uuid, position, "ArrowLeft");

        if (nextFocusableElement) {
          nextFocusableElement.focus();
        } else {
          // If, for some reason, the next focusable element could not be found, focus the button
          buttonRef.current?.focus();
        }
      }
    },
    [buttonRef, roverTabIndex],
  );

  const renderValueAsChip = useCallback(
    (el: T | undefined, index?: number) => {
      const chipUUID = index !== undefined ? chipsUUIDs?.[index] : undefined;
      const labelID = chipUUID ? `${chipUUID}-label` : undefined;

      return (
        <div tabIndex={disabled ? undefined : -1}>
          {el ? (
            <>
              <label className="hidden" id={labelID}>
                {`${el?.[labelKey] ?? ""}`}
              </label>

              <Chip
                ids={chipUUID ? { parent: chipUUID } : undefined}
                color="default"
                onDelete={
                  disabled
                    ? undefined
                    : (event) => {
                        event?.stopPropagation();
                        event?.preventDefault();

                        if (index !== undefined && chipsUUIDs[index]) {
                          updateFocusOnDelete(
                            chipsUUIDs?.[index],
                            getElementPositionInArray(chipsUUIDs.length, index),
                          );
                        }

                        onDeleteItem(el);
                      }
                }
                onKeyDown={
                  disabled
                    ? undefined
                    : (event) => {
                        // Delete the value on Backspace
                        if (event.code === "Backspace") {
                          event.preventDefault();
                          event.stopPropagation();

                          if (index !== undefined && chipsUUIDs[index]) {
                            updateFocusOnDelete(
                              chipsUUIDs[index],
                              getElementPositionInArray(chipsUUIDs.length, index),
                            );
                          }

                          onDeleteItem(el);
                        }

                        // Rover tabIndex on ArrowLeft & ArrowRight
                        if (
                          (event.code === "ArrowLeft" || event.code === "ArrowRight") &&
                          chipUUID &&
                          index !== undefined
                        ) {
                          roverTabIndex(
                            chipUUID,
                            getElementPositionInArray(chipsUUIDs.length, index),
                            event.code,
                          );
                        }
                      }
                }
                aria-labelledby={labelID}
                tabIndex={index === 0 ? 0 : -1}
                classNames={{
                  parent:
                    "focus-visible:border-2 focus-visible:border-[color:rgb(0,122,255)] focus-visible:m-0 m-[1px]",
                }}
              >
                {el?.[labelKey] ? `${el?.[labelKey]}` : ""}
              </Chip>
            </>
          ) : null}
        </div>
      );
    },
    [chipsUUIDs, disabled, labelKey, onDeleteItem, roverTabIndex, updateFocusOnDelete],
  );

  const renderValueAsString = useCallback(
    (el: T | undefined) => (el?.[labelKey] ? <Fragment>{`${el?.[labelKey]}`}</Fragment> : null),
    [labelKey],
  );

  const displayValue = useMemo(() => {
    const renderFunction = (el?: T, index?: number) => {
      if (renderValue && el) {
        return renderValue(el);
      }

      return multiple ? renderValueAsChip(el, index) : renderValueAsString(el);
    };

    return !value && !values?.length
      ? null
      : values?.length
        ? values?.map((el, index) => <div key={`${el?.[idKey]}`}>{renderFunction(el, index)}</div>)
        : renderFunction(value);
  }, [renderValue, multiple, renderValueAsChip, renderValueAsString, value, values, idKey]);

  return displayValue;
};
