import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { atom, selector } from "recoil";

import { IOrganizationUnitStructure } from "./organization-unit-selector.types";
import { getFlattenedOrganizationUnits } from "./organization-unit-selector.utils";

export const organizationUnitStructureServiceState = builders.buildServiceState({
  service: Core.GetOrganizationUnitsHierarchy,
  key: "organizationUnitStructureServiceState",
  requireEmployee: true,
  allowEmptyRequest: true,
  defaultRequest: {},
});

export const organizationUnitStructureResultSelector = selector({
  key: "organizationUnitStructureServiceState:Result",
  get: ({ get }) => get(organizationUnitStructureServiceState.response)?.OrganizationUnits,
});

export const flattenedOrganizationUnitsSelector = selector({
  key: "flattenedOrganizationUnitsSelector",
  get: ({ get }) => {
    const organizationUnits = get(organizationUnitStructureResultSelector);

    if (!organizationUnits) {
      return undefined;
    }

    const flattenedOUList = getFlattenedOrganizationUnits(organizationUnits);

    return flattenedOUList;
  },
});

export const selectedOrganizationUnitAtom = atom<IOrganizationUnitStructure | undefined>({
  key: "selectedOrganizationUnitAtom",
  default: undefined,
});

export const showOrganizationUnitSelectorAtom = atom<boolean | undefined>({
  key: "showOrganizationUnitSelector",
  default: undefined,
});

export const OrganizationUnitSelectorFilterAtom = atom<string | undefined>({
  key: "Composite:OrganizationUnitSelector:State:Filter",
  default: undefined,
});
