import { createContext, ReactNode, useContext, useMemo } from "react";

import { hooks, state } from "@springtree/eva-sdk-react-recoil";

import {
  flattenedOrganizationUnitsSelector,
  organizationUnitStructureResultSelector,
} from "./organization-unit-selector.state";
import { IOrganizationUnitStructure } from "./organization-unit-selector.types";

type OrganizationUnitSelectorContextType = {
  currentUser?: EVA.Core.GetCurrentUserResponse;
  organizationUnits?: EVA.Core.OrganizationUnitHierarchy[];
  flattenedOrganizationUnits?: IOrganizationUnitStructure[];
};

const OrganizationUnitSelectorContext = createContext<OrganizationUnitSelectorContextType>({});

export const OrganizationUnitSelectorProvider = ({ children }: { children: ReactNode }) => {
  const currentUser = hooks.useGetState(state.currentUser.currentUserState.response);
  const organizationUnits = hooks.useGetState(organizationUnitStructureResultSelector);
  const flattenedOrganizationUnits = hooks.useGetState(flattenedOrganizationUnitsSelector);

  const contextValue = useMemo(
    () => ({ currentUser, organizationUnits, flattenedOrganizationUnits }),
    [currentUser, flattenedOrganizationUnits, organizationUnits],
  );

  return (
    <OrganizationUnitSelectorContext.Provider value={contextValue}>
      {children}
    </OrganizationUnitSelectorContext.Provider>
  );
};

export const useOrganizationUnitSelectorContext = () => {
  const context = useContext(OrganizationUnitSelectorContext);
  if (!context) {
    throw new Error(
      "useOrganizationUnitSelectorContext must be used within a OrganizationUnitSelectorProvider",
    );
  }

  return context;
};
